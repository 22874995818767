<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        :max-width="bom_list.length > 1 ? '2000px' : '1000px'"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Production</span>

            <v-spacer></v-spacer>

            <v-btn
              ref="save"
              color="blue darken-1"
              class="mx-2"
              @click="save"
              small
            >
              Enregistrer
            </v-btn>

            <v-btn color="blue darken-1" class="mx-2" @click.stop="close" small>
              Fermer
            </v-btn>
          </v-card-title>
          <v-card-subtitle>
            <v-layout row justify-center align-center v-if="progress">
              <v-progress-linear
                indeterminate
                :size="30"
                :width="7"
                color="purple"
              ></v-progress-linear>
            </v-layout>
          </v-card-subtitle>
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="1" md="12" v-if="bom_list.length > 0">
                  <listitems
                    :list="bom_list"
                    :headers="inputheaders"
                    :master="false"
                    :add="false"
                    :del="false"
                    :Update="false"
                    :print="false"
                    :exp_excel="false"
                    :key="frml"
                    :ipg="5"
                    :show_chips_delete="editedItem.id < 0"
                    @chips_click="show_list"
                    @chips_delete="chip_delete"
                    @rowselect="IntrantChange"
                  >
                  </listitems>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="amalgame">
                  <v-row
                    v-for="(article, index) in articles"
                    :key="article.id"
                    dense
                  >
                    <v-col cols="12" sm="6" md="6">
                      <v-subheader>
                        {{ article.article_code + "-" + article.product_name }}
                      </v-subheader>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        autocomplete="off"
                        :ref="'code' + index"
                        dense
                        v-model="article.code"
                        :label="'Code'"
                        :rules="[(v) => !!v || 'Code obligatoire']"
                        :readonly="code_readonly || readonly"
                        hide-spin-buttons
                        @focus="$event.target.select()"
                        @input="alert = false"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        autocomplete="off"
                        :ref="'qte' + index"
                        class="inputPrice"
                        type="number"
                        dense
                        v-model.number="article.qte"
                        :label="
                          'Quantité ' +
                          (editedItem.unit ? '(' + editedItem.unit + ')' : '')
                        "
                        :rules="[
                          (v) => !!v || 'Quantité obligatoire',
                          (v) => v > 0 || 'Valeur incorrecte ',
                          (v) =>
                            v >= minvalue ||
                            tosell == '0' ||
                            tosell == null ||
                            minvalue == null ||
                            'Min ' + minvalue,
                          (v) =>
                            v <= maxvalue ||
                            tosell == '0' ||
                            tosell == null ||
                            maxvalue == null ||
                            'Max ' + maxvalue,
                        ]"
                        :readonly="readonly"
                        hide-spin-buttons
                        @focus="$event.target.select()"
                        @keydown.enter="save"
                        @input="alert = false"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2">
                      <v-text-field
                        autocomplete="off"
                        ref="comment"
                        dense
                        v-model="editedItem.comment"
                        :label="'Commentaire'"
                        @focus="$event.target.select()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="isSimple">
                  <v-autocomplete
                    ref="articles"
                    :items="articles"
                    v-model="editedItem.article_id"
                    item-value="id"
                    label="Produit"
                    @change="article_change"
                    :readonly="readonly"
                    :rules="[(v) => !!v || 'Produit obligatoire']"
                    dense
                  >
                    <template v-slot:selection="{ item }">
                      <!-- HTML that describe how select should render selected items -->
                      {{
                        (item.code ? item.code + "-" : "") + item.product_name
                      }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.product_name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.article_code"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" md="4" v-if="!amalgame">
                  <v-text-field
                    autocomplete="off"
                    ref="code"
                    dense
                    v-model="editedItem.code"
                    :label="'Code'"
                    :rules="[(v) => !!v || 'Code obligatoire']"
                    :readonly="code_readonly || readonly"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                    @keydown.enter="$refs.qte.focus()"
                    @input="alert = false"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="2" v-if="!amalgame">
                  <v-text-field
                    autocomplete="off"
                    ref="qte"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.qte"
                    :label="
                      'Quantité ' +
                      (editedItem.unit ? '(' + editedItem.unit + ')' : '')
                    "
                    :rules="[
                      (v) => !!v || 'Quantité obligatoire',
                      (v) => v > 0 || 'Valeur incorrecte ',
                      (v) =>
                        v >= minvalue ||
                        tosell == '0' ||
                        tosell == null ||
                        minvalue == null ||
                        'Min ' + minvalue,
                      (v) =>
                        v <= maxvalue ||
                        tosell == '0' ||
                        tosell == null ||
                        maxvalue == null ||
                        'Max ' + maxvalue,
                    ]"
                    :readonly="readonly"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                    @keydown.enter="save"
                    @input="alert = false"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="6" v-if="!amalgame">
                  <v-text-field
                    autocomplete="off"
                    ref="comment"
                    dense
                    v-model="editedItem.comment"
                    :label="'Commentaire'"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-alert v-model="alert" :type="type_alert" dismissible>{{
              message
            }}</v-alert>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <confirmdialog ref="confirm" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <formlist
      :title="'Liste Articles'"
      :list="input_detail"
      :showform="!isListClose"
      :listheaders="colisheaders"
      :key="fl"
      :btn_save="true"
      :btn_save_dis="false"
      :Total="true"
      :multiple="true"
      @close="isListClose = true"
      @save="update_current"
      width="1400px"
    >
    </formlist>
  </div>
</template>

<script>
import INSERT_FICHE_DETAIL from "../graphql/Production/INSERT_FICHE_DETAIL.gql";

export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    listitems: () => import("./ListItems.vue"),
    formlist: () => import("../components/FormList.vue"),
  },
  name: "ficheoutputform",
  props: {
    item: Object,
    items: Array,
    fiche: Object,
    bt: Object,
    machine: Object,
    product_in: Object,
    showForm: Boolean,
    attributes: Array,
    bom_list: Array,
    input_list: Array,
    code: String,
    num_seq: Number,
  },
  data: () => ({
    code_readonly: false,
    adding: false,
    deleting: false,
    productload: false,
    isListClose: true,
    intrant: {},
    editedItem: {},
    list: false,
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    tol: 0,
    precision: 2,
    inputheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
      },
      {
        text: "Produit/Gamme",
        value: "label",
        selected: true,
      },
      {
        text: "Parents",
        value: "currents",
        selected: true,
        slot: "chips",
        color: "blue",
      },
    ],
    colisheaders2: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Code Article",
        value: "article_code",
        selected: true,
        hiden: true,
      },
      {
        text: "Libelle",
        value: "label",
        selected: true,
        hiden: true,
      },
      {
        text: "Code",
        value: "code",
        selected: true,
        slot: "chip",
        color: "color",
        edit: true,
        type: "text",
        sm: 4,
        md: 4,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "cur",
        align: "end",
        selected: true,
        barre: true,
        column: "qte_rest",
        valeur: 0,
      },
      {
        text: "Qte Rest.",
        value: "qte_rest",
        total: "qte_rest",
        slot: "cur",
        align: "end",
        selected: true,
      },
      {
        text: "% Rest.",
        value: "prc_rest",
        slot: "progress",
        selected: true,
      },
      {
        text: "Comment",
        value: "comment",
        selected: true,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
    ],
    colisheaders: [],
    fl: 500,
    frml: 600,
    qte_max: 0,
    parents: [],
    alert: false,
    type_alert: "info",
    message: "",
    input_detail: [],
    readonly: false,
  }),

  computed: {
    minvalue() {
      let m;
      if (this.bt) if (this.bt.order) m = this.bt.order.minvalue;
      if (this.product_in.produit_id) {
        m = this.editedItem.minvalue;
      }
      return m;
    },
    maxvalue() {
      let m;
      if (this.bt) if (this.bt.order) m = this.bt.order.maxvalue;
      if (this.product_in.produit_id) {
        m = this.editedItem.maxvalue;
      }
      return m;
    },
    tosell() {
      let m;
      if (this.bt.id) m = this.bt.tosell;
      return m;
    },
    articles() {
      let l = [];
      if (this.bt.id)
        this.bt.order.links.forEach((element) => {
          for (let index = 0; index < element.ratio; index++) {
            l.push({
              id: element.link_id,
              article_code: element.article_code,
              label: element.label,
              product_name: element.label,
              ratio: element.ratio,
              unit: element.unit,
              bylist: element.bylist,
              maxvalue: element.maxvalue,
              minvalue: element.minvalue,
            });
          }
        });
      if (this.product_in && this.items)
        if (this.product_in.products_out)
          this.product_in.products_out.forEach((element) => {
            element.articles
              .filter(
                (elm) =>
                  elm.tier_id == this.fiche.tier_id &&
                  (!this.items.map((elm) => elm.article_id).includes(elm.id) ||
                    element.bylist == "1")
              )
              .forEach((elm) => {
                l.push({
                  id: elm.id,
                  label: elm.label,
                  article_code: elm.article_code,
                  product_name: elm.product_name,
                  tier_name: elm.tier_name,
                  ratio: element.factor,
                  unit: element.unit,
                  bylist: element.bylist,
                  maxvalue: element.maxvalue,
                  minvalue: element.minvalue,
                });
              });
          });
      return l;
    },
    amalgame() {
      let ok = false;
      if (this.bt.id) {
        if (this.bt.tosell == "1" && this.bt.order.links.length > 0) ok = true;
      }
      return ok;
    },
    isSimple() {
      let ok = false;
      if (this.product_in.produit_id) {
        ok = true;
      }
      return ok;
    },

    attributs_list() {
      return this.attributes.filter((elm) => elm.type_attr == "colis");
    },
    showDetail() {
      return this.showForm;
    },
  },
  watch: {},

  created() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.readonly = true;

        for (let index = 0; index < this.bom_list.length; index++) {
          const intrant = this.bom_list[index];

          let l = this.editedItem.parents
            .filter((elm) => elm.fk_pob == intrant.id)
            .map((elm) => elm.code);
          if (this.isSimple) l = this.editedItem.parents.map((elm) => elm.code);
          if (intrant.produit_id) intrant.currents = l;
          if (intrant.gamme_id)
            intrant.currents = this.editedItem.parents
              .filter((elm) => elm.gamme_id == intrant.gamme_id)
              .map((elm) => elm.code);
        }
      } else {
        this.editedItem.id = -1;
        if (this.isSimple && this.articles.length == 1)
          this.editedItem.article_id = this.articles[0].id;
        if (this.code) {
          if (this.amalgame) {
            let i = this.num_seq;
            this.articles.forEach((element) => {
              element.code = this.machine.label + "-" + i;
              i++;
            });
          } else this.editedItem.code = this.code;
          //this.code_readonly = true;
          if (this.$refs.qte) this.$refs.qte.focus();
        } else {
          this.code_readonly = false;
          if (this.$refs.code && !this.amalgame) this.$refs.code.focus();
          if (this.$refs.code0 && this.amalgame) this.$refs.code0.focus();
        }
      }
    }
    if (this.bt.order) {
      if (this.bt.order.bylist) this.editedItem.bylist = this.bt.order.bylist;
      if (this.bt.order.unit) this.editedItem.unit = this.bt.order.unit;
    }
  },
  async mounted() {},

  methods: {
    article_change() {
      let i = this.articles.findIndex(
        (elm) => elm.id == this.editedItem.article_id
      );
      if (i >= 0) {
        this.editedItem.unit = this.articles[i].unit;
        this.editedItem.bylist = this.articles[i].bylist;
        this.editedItem.maxvalue = this.articles[i].maxvalue;
        this.editedItem.minvalue = this.articles[i].minvalue;
      }
    },
    sortBy(prop) {
      return function (a, b) {
        return a[prop] > b[prop] ? 1 : -1;
      };
    },

    verif_intrants() {
      let ok = true;
      this.parents = [];
      let qte;
      let l = [];
      let rest = 0;
      for (let index = 0; index < this.bom_list.length; index++) {
        const intrant = this.bom_list[index];
        qte = 0;
        rest = 0;
        if (this.amalgame) {
          for (let index = 0; index < this.articles.length; index++) {
            const element = this.articles[index];
            if (element.qte > qte) qte = element.qte;
          }
          if (intrant.current_id)
            rest = intrant.input_detail
              .filter((elm) => intrant.current_id.includes(elm.id))
              .reduce((a, b) => a + (b["qte_rest"] || 0), 0);
        } else if (this.isSimple) {
          let i = this.articles.findIndex(
            (elm) => elm.id == this.editedItem.article_id
          );
          if (i >= 0) {
            qte = this.editedItem.qte * this.articles[i].ratio;
            if (intrant.current_id)
              rest = intrant.input_detail
                .filter((elm) => intrant.current_id.includes(elm.id))
                .reduce((a, b) => a + (b["qte_rest"] || 0), 0);
          }
        } else {
          qte = intrant.ratio * this.editedItem.qte;
          if (intrant.current_id)
            rest = intrant.input_detail
              .filter((elm) => intrant.current_id.includes(elm.id))
              .reduce((a, b) => a + (b["qte_rest"] || 0), 0);
        }

        //verifier selection
        if (!intrant.current_id) {
          this.message =
            "Veuillez selectionner l'intrant N° " +
            (index + 1) +
            " " +
            intrant.code;
          this.type_alert = "error";
          this.alert = true;
          ok = false;
        }
        //verifier min
        if (qte > 0 && qte > rest && ok) {
          this.message =
            "Attention quantité insufisante pour l'intrant N° " +
            (index + 1) +
            " " +
            intrant.code +
            " --Verifier";
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          break;
        }
        //verifier max
        if (intrant.current_id && ok) {
          let m = 1;
          l = intrant.input_detail.filter((elm) =>
            intrant.current_id.includes(elm.id)
          );
          for (let index = 0; index < l.length; index++) {
            const element = l[index];

            if (element.qte_rest > qte) break;
            else m++;
          }
          if (intrant.current_id.length > m && ok) {
            this.message =
              "Choisir Maximum " + m + " articles pour " + intrant.code;
            this.type_alert = "error";
            this.alert = true;
            ok = false;
            break;
          }
        }

        l.sort(this.sortBy("qte_rest"));
        l.forEach((element) => {
          let q = qte < element.qte_rest ? qte : element.qte_rest;
          if (intrant.produit_id)
            this.parents.push({
              fk_pob: this.bt.id ? intrant.id : null,
              product_parent_id: element.id,
              qte: q,
              article_id: element.article_id,
              code: element.code,
            });
          else
            this.parents.push({
              production_parent_id: element.id,
              qte: q,
              gamme_id: intrant.gamme_id,
              code: element.code,
            });

          qte = qte - q;
        });
      }
      return ok;
    },
    async IntrantChange(item) {
      this.intrant = item;
      this.colisheaders2[1].hiden = item.gamme_id > 0;
      this.colisheaders2[2].hiden = item.gamme_id > 0;
      this.init_list();
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    init_list() {
      this.colisheaders = [];
      this.colisheaders2.forEach((element) => {
        this.colisheaders.push(element);
      });
      if (this.product_in.produit_id) this.colisheaders[5].hiden = true;

      this.colisheaders[4].text = "Qte (" + this.intrant.unit + ")";
      this.colisheaders[5].text = "Qte Rest. (" + this.intrant.unit + ")";

      this.colisheaders[4].dec = this.intrant.virgule;
      this.colisheaders[5].dec = this.intrant.virgule;
      let colis_attributs = [];
      colis_attributs = this.intrant.ppattributs
        ? this.intrant.ppattributs.split(",")
        : [];

      for (let index = 0; index < colis_attributs.length; index++) {
        const element = colis_attributs[index];

        let i = this.attributs_list.findIndex((elm) => elm.id == element);
        if (i >= 0) {
          let ls = [];
          this.attributs_list[i].attributvalues.forEach((element) => {
            ls.push(element.name);
          });
          let elm = {
            text: this.attributs_list[i].caption
              ? this.attributs_list[i].caption
              : this.attributs_list[i].name,
            value: "ref" + index,
            selected: true,
            edit: true,
            key: this.attributs_list[i].key,
            type:
              this.attributs_list[i].attributvalues.length > 0
                ? "autocomplete"
                : "text",
            select_list: ls,
            show_list: ls,
            sm: 6,
            md: 6,
          };
          this.colisheaders.splice(index + 4, 0, elm);
        }
      }
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },

    async requette(query, v) {
      this.loading = true;
      let r = [];

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    chip_delete(item, chip) {
      if (this.editedItem.id < 0) {
        this.intrant = item;
        let i = this.intrant.input_detail.findIndex((elm) => elm.code == chip);
        if (i >= 0) {
          this.intrant.input_detail[i].selected = false;
        }
        this.update_current(this.intrant.input_detail);
      }
    },
    show_list(item) {
      this.alert = false;
      if (this.editedItem.id < 0) {
        this.intrant = item;
        let l = [];
        this.bom_list
          .filter((elm) => elm.id != item.id)
          .forEach((element) => {
            if (element.current_id)
              element.current_id.forEach((elm) => {
                l.push(elm);
              });
          });
        this.input_detail = this.intrant.input_detail.filter(
          (elm) => !l.includes(elm.id)
        );
        this.fl++;
        this.isListClose = false;
      }
    },
    update_current(items) {
      this.intrant.currents = items
        .filter((elm) => elm.selected)
        .map((elm) => elm.code);
      this.intrant.current_id = items
        .filter((elm) => elm.selected)
        .map((elm) => elm.id);
      this.isListClose = true;
      this.frml++;
    },
    async insert_output() {
      let list = [];
      let elm = {};
      if (this.amalgame) {
        this.articles.forEach((element) => {
          elm = {};
          elm.code = element.code;
          elm.qte = element.qte;
          elm.article_id = element.id;
          list.push(elm);
        });
      } else {
        elm.code = this.editedItem.code;
        elm.qte = this.editedItem.qte;
        elm.article_id = this.isSimple
          ? this.editedItem.article_id
          : this.bt.order.article_id;
        list.push(elm);
      }
      //
      let v = {
        create_uid: this.$store.state.me.id,
        fiche_id: this.fiche.id,
        list: list,
        parents: this.parents,
      };
      let r = await this.maj(INSERT_FICHE_DETAIL, v);
      return r;
    },
    verifie_double() {
      let ok = true;
      if (this.amalgame) {
        for (let index = 0; index < this.articles.length; index++) {
          const element = this.articles[index];
          let l = this.articles.filter((elm) => elm.id != element.id);
          for (let index = 0; index < l.length; index++) {
            const elm = l[index];
            if (elm.code.toLowerCase() == element.code.toLowerCase()) {
              ok = false;
              break;
            }
          }
          for (let index = 0; index < this.items.length; index++) {
            const elm = this.items[index];
            if (elm.code.toLowerCase() == element.code.toLowerCase()) {
              ok = false;
              break;
            }
          }
          if (ok == false) break;
        }
      } else {
        let i = this.items.findIndex(
          (elm) => elm.code.toLowerCase() == this.editedItem.code.toLowerCase()
        );
        if (i >= 0) ok = false;
      }
      if (ok == false) {
        this.message = "Erreur code en double! verifier";
        this.type_alert = "error";
        this.alert = true;
        ok = false;
      }
      return ok;
    },
    async save() {
      if (this.$refs.form.validate()) {
        let ok = true;
        ok = this.verifie_double();
        if (ok)
          if (this.verif_intrants()) {
            // verifier les quantites necessaire des intrants
            if (this.bt.qte && !this.amalgame) {
              let qte_fab =
                this.bt.qte_fab +
                (this.items
                  ? this.items.reduce((a, b) => a + (b["qte"] || 0), 0)
                  : 0) +
                this.editedItem.qte;
              if (qte_fab > this.bt.qte) {
                ok = false;
                if (
                  await this.$refs.confirm.open(
                    "Confirmation",
                    "Attention dépassement quantité bon de travail-Etês-vous sûr ?",
                    "",
                    qte_fab -
                      this.bt.qte +
                      " " +
                      this.bt.order.unit +
                      " en exces"
                  )
                )
                  ok = true;
              }
            }

            if (ok) {
              this.editedItem.parents = this.parents;
              let r = await this.insert_output();
              if (r) {
                let d = JSON.parse(r.InsertFicheDetail);
                if (this.amalgame) {
                  this.articles.forEach((element) => {
                    element.parents = this.parents;
                    let i = d.findIndex((elm) => elm.article_id == element.id);
                    if (i >= 0) {
                      element.id = d[i].id;
                      element.amg_id = d[i].amg_id;
                    }
                  });
                } else this.editedItem.id = d[0].id;
                if (this.isSimple) {
                  let i = this.articles.findIndex(
                    (elm) => elm.id == this.editedItem.article_id
                  );
                  this.editedItem.label = this.articles[i].label;
                  this.editedItem.ratio = this.articles[i].ratio;
                }
                let rest = 0;
                let qte = 0;
                //maj input list
                for (let index = 0; index < this.bom_list.length; index++) {
                  const intrant = this.bom_list[index];
                  if (this.amalgame) {
                    for (let index = 0; index < this.articles.length; index++) {
                      const element = this.articles[index];
                      if (element.qte > qte) qte = element.qte;
                    }
                    rest = intrant.input_detail
                      .filter((elm) => intrant.current_id.includes(elm.id))
                      .reduce((a, b) => a + (b["qte"] || 0), 0);
                  } else if (this.isSimple) {
                    qte = this.editedItem.qte * this.editedItem.ratio;
                    rest = intrant.input_detail
                      .filter((elm) => intrant.current_id.includes(elm.id))
                      .reduce((a, b) => a + (b["qte_rest"] || 0), 0);
                  } else {
                    qte = intrant.ratio * this.editedItem.qte;
                  }
                  let l = intrant.input_detail.filter((elm) =>
                    intrant.current_id.includes(elm.id)
                  );
                  l.sort(this.sortBy("qte_rest"));

                  l.forEach((element) => {
                    let q = qte < element.qte_rest ? qte : element.qte_rest;
                    element.qte_rest = this.amalgame
                      ? rest - q
                      : element.qte_rest - q;
                    element.prc_rest = (
                      (element.qte_rest / element.qte) *
                      100
                    ).toFixed(2);
                    //maj consomation
                    let k = this.input_list.findIndex(
                      (elm) => elm.article_id == element.article_id
                    );
                    if (k >= 0)
                      this.input_list[k].qte = this.input_list[k].qte + q;
                    if (element.qte_rest == 0) {
                      element.selected = false;
                      intrant.current_id.splice(
                        intrant.current_id.findIndex(
                          (elm) => elm == element.id
                        ),
                        1
                      );
                      intrant.currents.splice(
                        intrant.currents.findIndex(
                          (elm) => elm == element.code
                        ),
                        1
                      );
                      element.disabled = true;
                      element.color = "gray";
                    }
                    qte = qte - q;
                    rest = rest - q;
                  });
                }
                this.$emit(
                  "save",
                  this.amalgame ? this.articles : this.editedItem
                );
              }
            }
          }
      }
    },
    async close() {
      this.$emit("close");
    },
  },
};
</script>
